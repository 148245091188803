import { render, staticRenderFns } from "./invitingrecord.vue?vue&type=template&id=1aba6f4a"
import script from "./invitingrecord.vue?vue&type=script&lang=ts"
export * from "./invitingrecord.vue?vue&type=script&lang=ts"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./invitingrecord.vue?vue&type=style&index=1&id=1aba6f4a&prod&lang=stylus"
import style2 from "./invitingrecord.vue?vue&type=style&index=2&id=1aba6f4a&prod&module=true&lang=stylus"




function injectStyles (context) {
  
  this["$style"] = (style2.locals || style2)

}

/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports