
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import { request as axios } from '@/util/request';
import moment from 'moment';
import { detailProduct } from '@/api/factory/product';

const getUrl = require('bmh/url-with-param');

interface SearchType {
  invitee_name: string ;
  inviter_name: string;
  status: number | string;
  invitee_mobile: string;
  time: any;
  arrive_time: any;
}

@Component({
  name:'Invitingrecord',
  components: {},
})
export default class Invitingrecord extends Vue {
  is_loading: boolean = false;
  current: number = 1;
  searchList: SearchType = {
    invitee_name: '',
    inviter_name: '',
    status: '',
    invitee_mobile: '',
    time: '',
    arrive_time: '',
  };
  columns: any = [
    {
      title: '被邀请人',
      dataIndex: 'invitee_name',
      width: 150,
    },
    {
      title: '联系电话',
      dataIndex: 'invitee_mobile',
      width: 100,
    },
    {
      title: '邀请人',
      dataIndex: 'inviter_name',
      width: 100,
    },
    {
      title: '邀请时间',
      dataIndex: 'create_time',
      width: 100,
    },
    {
      title: '奖励到账时间',
      dataIndex: 'arrive_time',
      width: 150,
    },
    {
      title: '邀请佣金（元）',
      dataIndex: 'bonus',
      scopedSlots: { customRender: 'bonus' },
      width: 100,
    },
    {
      title: '状态',
      dataIndex: 'status',
      scopedSlots: { customRender: 'status' },
      width: 100,
    //   fixed: 'right',
    },
  ];
  invite_data: any = [];
  handleSearch(){
    this.current = 1
    this.search()
  }
  @changeLoading(['is_loading'])
  async search() {
     const data: any = {
        ...this.searchList,
        current:this.current
    }
    Object.keys(data).forEach((item)=>{
        if(data[item] == "") delete data[item]
    })
    if(data.time){
        data.start_time = new Date(moment(data.time[0]).format('YYYY-MM-DD')+" 00:00:00").getTime()/1000
        data.end_time = new Date(moment(data.time[1]).format('YYYY-MM-DD')+" 23:59:59").getTime()/1000
        delete data.time
    }
    if(data.arrive_time){
        data.arrive_start_time = new Date(moment(data.arrive_time[0]).format('YYYY-MM-DD')+" 00:00:00").getTime()/1000
        data.arrive_end_time = new Date(moment(data.arrive_time[1]).format('YYYY-MM-DD')+" 23:59:59").getTime()/1000
        delete data.arrive_time
    }
    const url = getUrl("/boss/knight/inviting",data);
    
    const res = await axios.get(url)
    res.data.detail.forEach((item: any,index: any)=>{
        res.data.detail[index].create_time = moment(item.create_time*1000).format('YYYY-MM-DD')
        res.data.detail[index].arrive_time = item.arrive_time == 0 ? "-":moment(item.arrive_time*1000).format('YYYY-MM-DD')
    })
    this.invite_data = res.data
    
  }
  clearSearch() {
    this.searchList ={
        invitee_name: '',
        inviter_name: '',
        status: '',
        invitee_mobile: '',
        time: '',
        arrive_time: '',
    }
  }
  paginationOption(data: RemotePagination<BannerTableData>) {
    return {
      total: data.total || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
      pageSize: data.limit || 1,
      current: Number(data.current) || 1,
    };
  }
  onTableChange(pagination: any) {
    this.current = pagination.current;
    this.search();
  }
  async created(){
    await this.search()

  }
}
